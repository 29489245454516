import { api } from '@/api/api';
export const getFeaturedPlaylist = async ()=>{
    const { data } = await api.get('/track/styles/featuredPlaylist');
    const styles = data.styles.sort((a, b)=>a.category.order - b.category.order).map((param)=>{
        let { category, results } = param;
        return {
            category,
            tiles: results
        };
    });
    return {
        creatorPicks: data.playlists,
        styles
    };
};
